import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay, faPause, faCheck, faXmark, faArrowsRotate } from '@fortawesome/free-solid-svg-icons'
import { Descriptions, Row, Col, Button, Alert } from 'antd';

import Axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';

//Redux
import { useSelector, useDispatch } from 'react-redux';
import { setAuth } from '../redux/appmgtSlice'

//Environment
import { chooseEnvironment } from '../utils/chooseEnvironment';


export function ManageCycle() {
    // Possibilities wait/pause/completed/aborted/startover/main

    const items_completed = [

        {
            key: '1',
            label: 'Completed',
            children: <Button onClick={() => manageCycle('completed')} style={{ background: "#5b5981" }} type="primary" size="large" shape="round"><FontAwesomeIcon icon={faCheck} size="xl" /></Button>,
            span: 2,
        },

        {
            key: '3',
            label: 'Completed Message',
            children: 'Stock cycle completed. No further work is needed. Thank you !',
            span: 3,
        },

    ];

    const items_cancelled = [

        {
            key: '1',
            label: 'Cancel',
            children: <Button onClick={() => manageCycle('aborted')} style={{ background: "#5b5981" }} type="primary" size="large" shape="round"><FontAwesomeIcon icon={faXmark} size="xl" /></Button>,
            span: 2,
        },

        {
            key: '3',
            label: 'Cancel Message',
            children: 'This stock cycle has been cancelled. No further work is needed. Please proceed with your normal work or wait to receive instructions from management. Thank you !',
            span: 3,
        },

    ];


    const items_startingover = [

        {
            key: '1',
            label: 'Start Over',
            children: <Button onClick={() => manageCycle('startover')} style={{ background: "#5b5981" }} type="primary" size="large" shape="round"><FontAwesomeIcon icon={faArrowsRotate} size="xl" /></Button>,
            span: 2,
        },

        {
            key: '3',
            label: 'Starting Over Message',
            children: 'Unfortunately this stock cycle will have to start over. Please wait until you are automatically allowed to proceed to the next screen. You may also receive further instructions from management. We appologise for the inconvenience. Thank you for your patience !',
            span: 3,
        },

    ];

    //Environment
    let envUrl = chooseEnvironment();

    //Redux
    const dispatch = useDispatch();
    const token = useSelector((state) => state.appmgt.token);

    const customConfig = {
        headers: {
            'Content-Type': 'application/json',
            "x-auth-token": token,
        },
    };


    async function manageCycle(cyclestate) {

        let doc = {
            "company": 'Evolve',
            "cyclestate": cyclestate
        }

        await Axios.post(envUrl + '/api/updateCycleState', doc, customConfig).then((response) => {

            if (response.data.token) {
                notify(cyclestate);
            } else {
                notify('error');
                dispatch(setAuth(response.data.token));
            }

        }).catch(function (error) {
            console.log(error);

        });

    };

    return (
        <>

            <ToastContainer
                position="top-center"
                autoClose={300}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />

            <Row>
                <Col span={1} className="settingsheader"></Col>
                <Col span={12} className="settingsheader">

                    <Descriptions title="Cycle Commands" bordered layout="vertical">

                        <Descriptions.Item label="Start">
                            <Button onClick={() => manageCycle('main')} style={{ background: "#5b5981" }} type="primary" size="large" shape="round"><FontAwesomeIcon icon={faPlay} size="xl" /></Button>

                        </Descriptions.Item>
                        <Descriptions.Item label="Pause" >
                            <Button onClick={() => manageCycle('pause')} style={{ background: "#5b5981" }} type="primary" size="large" shape="round"><FontAwesomeIcon icon={faPause} size="xl" /></Button>
                        </Descriptions.Item>
                    </Descriptions>

                </Col>
                <Col span={11} className="settingsheader"></Col>
            </Row>

            <Row>
                <Col span={1} className="settingsheader"></Col>
                <Col span={12} className="settingsheader">
                    <Descriptions title="Personell Notification - Completed" bordered items={items_completed} layout="vertical" />
                </Col>
                <Col span={11} className="settingsheader"></Col>
            </Row>

            <Row>
                <Col span={1} className="settingsheader"></Col>
                <Col span={12} className="settingsheader">
                    <Descriptions title="Personell Notification - Cancelled" bordered items={items_cancelled} layout="vertical" />
                </Col>
                <Col span={11} className="settingsheader"></Col>
            </Row>

            <Row>
                <Col span={1} className="settingsheader"></Col>
                <Col span={12} className="settingsheader">
                    <Descriptions title="Personell Notification - Start Over" bordered items={items_startingover} layout="vertical" />
                </Col>
                <Col span={11} className="settingsheader"></Col>
            </Row>
            <Row>
                <Col span={1} className="settingsheader"></Col>
                <Col span={12} className="settingsheader">
                    <Alert
                        message="Note"
                        description="If personell are out of reach of a wi-fi or mobile network, notifications & commands may not reach them."
                        type="info"
                        showIcon
                    />
                </Col>
                <Col span={11} className="settingsheader"></Col>
            </Row>
            <Row>
                <Col span={24} className="settingsbottom"></Col>
            </Row>
        </>
    )
}

function notify(check) {

    if (check === 'main') {
        toast.success('Cycle Started', {
            position: "top-center",
            autoClose: 1800,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });

    } else if (check === 'pause') {
        toast.success('Cycle Paused', {
            position: "top-center",
            autoClose: 1800,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });

    } else if (check === 'completed') {
        toast.success('Notified as Cycle Completed.', {
            position: "top-center",
            autoClose: 1800,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });

    } else if (check === 'aborted') {
        toast.success('Notified as Cycle Cancelled.', {
            position: "top-center",
            autoClose: 1800,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });

    } else if (check === 'startover') {
        toast.success('Notified as Cycle Starting Over.', {
            position: "top-center",
            autoClose: 1800,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    } else {
        toast.error('Command Failed', {
            position: "top-center",
            autoClose: 1800,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }
}